const methods = {
    // # 입력칸 콤마
    inputToComma(value) {
        return value = this.$toComma(this.$toUnComma(value))
    },

    // # 콤마 추가
    toComma(value) {
        if(!value) return ''

        let parts = value.toString().split(".")
        parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return parts.join(".")
    },

    // # 콤마 제거
    toUnComma(value) {
        if (!value) return ''

        let parts = value.toString().split(".")
        parts[0] = parts[0].replace(/,/g, '')
        return parts.join(".")
    }
}

export default methods