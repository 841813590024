import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { routes } from './routes.js'
import store from './store.js'
import vuetify from './plugins/vuetify'
import lodash from 'lodash'
import '@babel/polyfill'

import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

Vue.use(
    Chartkick.use(Chart)
)

// Math.js 인스턴스 생성 및 설정 (부동소수점 문제 해결)
import { create, all } from 'mathjs'
const math = create(all)
math.config({
	number: 'BigNumber',
	precision: 64
})
Vue.prototype.$math = math

// Axios
import axios from 'axios'
Vue.prototype.$http = axios

// CustomMethods
import CustomMethods from './methods'
Vue.use(CustomMethods)

Vue.config.productionTip = false

Vue.use(Router)
export const eventBus = new Vue()

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 페이지별 Title
router.beforeEach(async(to, from, next) => {
    let title = ''

    if(to.path.startsWith('/admin')){
        title = '관리자페이지'
    }else if(to.path.startsWith('/home')){
        title = 'HOME'
    }

    document.title = title + (title==''?'':' - ') + '계산/환전기 MVP'
    next()
})

new Vue({
    router,
    store,
    vuetify,
    lodash,
    render: h => h(App)
}).$mount('#app')