const methods = {
    // # 메인 계산식
    formula_main(number, scale, sub_tab){
        // 숫자 없을 시
        if(!number) return ""

        // 실수 + 단위 없음 
        if (number && number.toString().includes('.') && scale == null){
            // 숫자 그대로 표시
            return this.$toComma(number)
        }

        // 숫자 + %
        else if (number && number.toString().endsWith('%')) {
            // 숫자 그대로 표시
            return this.$toComma(number)
        }

        // # 서브탭이 "000"인 경우
        if(sub_tab == "000"){
            // 숫자 X 단위 => 계산된 숫자
            return this.$toComma(this.$caculateTo000(number, scale))
        }

        // # 서브탭이 "만억조"인 경우
        else if(sub_tab == "만억조"){
            // 단위 없을 시
            if(scale == null){
                return this.$toComma(this.$caculateNumberToMOJ(number))
            }
            // 단위가 만억조
            else if(["만", "억", "조"].includes(scale)){
                return this.$toComma(parseFloat(number)) + scale
            }
            // 단위가 KMB
            else if(["K", "M", "B"].includes(scale)){
                return this.$toComma(this.$caculateKMBtoMOJ(number, scale))
            }
        }

        // # 서브탭이 "KMB"인 경우
        else if(sub_tab == "KMB"){
            // 단위 없을 시
            if(scale == null){
                return this.$toComma(this.$caculateNumberToKMB(number))
            }
            // 단위가 KMB
            else if(["K", "M", "B"].includes(scale)){
                return this.$toComma(parseFloat(number)) + scale
            }
            // 단위가 만억조
            else if(["만", "억", "조"].includes(scale)){
                return this.$toComma(this.$caculateMOJtoKMB(number, scale))
            }
        }
    },

    // # 서브 계산식
    formula_sub(number, scale, sub_tab, input_tab){
        // 숫자 없을 시
        if(!number) return ""

        // 숫자 + %
        if (number.toString().endsWith('%')) return ""
        
        // 서브탭이 "만억조" or "KMB" 일 시
        if(sub_tab == "만억조" || sub_tab == "KMB") return ""

        // bignumber로 변경
        let bignumber = this.$math.bignumber(number)


        // # 일반 (서브탭이 "000"인 경우)

        // # 입력탭이 만억조
        if(input_tab == "만억조"){
            // 단위 없을 시
            if(scale == null){
                // 숫자가 만보다 작으면 표시안함
                if(this.$math.compare(number, 10000) < 0){
                    return ''
                }
                // 숫자가 만보다 크면 만억조로 표시
                else{
                    return this.$toComma(this.$caculateNumberToMOJ(number))
                }
            }

            // 단위가 만억조
            else if(["만", "억", "조"].includes(scale)){
                // 숫자 + 단위로 표시
                return this.$toComma(parseFloat(number)) + scale
            }

            // 단위가 KMB
            else if(["K", "M", "B"].includes(scale)){
                // 숫자 + 단위(KMB) => 계산된 숫자 + 단위(만억조)
                return this.$toComma(this.$caculateKMBtoMOJ(number, scale))
            }
        }

        // # 입력탭이 KMB
        else if(input_tab == "KMB"){
            // 단위 없을 시 
            if(scale == null){
                // 숫자가 K보다 작으면 표시안함
                if(this.$math.compare(number, 1000) < 0){
                    return ''
                }
                // 숫자가 K보다 크면 KMB로 표시
                else{
                    return this.$toComma(this.$caculateNumberToKMB(number))
                }
            }

            // 단위가 KMB
            else if(["K", "M", "B"].includes(scale)){
                // 숫자 + 단위로 표시
                return this.$toComma(parseFloat(number)) + scale
            }

            // 단위가 만억조
            else if(["만", "억", "조"].includes(scale)){
                // 숫자 + 단위(만억조) => 계산된 숫자 + 단위(KMB)
                return this.$toComma(this.$caculateMOJtoKMB(number, scale))
            }
        }
    },
}

export default methods