<template>
    <v-app :class="rotateClass">
        <router-view />
    </v-app>
</template>
<script>
export default {
    name: 'App',
    computed: {
        rotateClass() {
            return this.$store.state.rotate ? 'rotate' : ''
        }
    }
}
</script>
<style>
.rotate {
    transform: rotate(180deg);
}
</style>