import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#0369A1",
                lighter_primary: "#81b4d0",
                alter_black: "#374151",
                alter_grey: "#667080", // 글씨색/적당히 잘보이는 색
                operator: "#AE7525"
            }
        },
    },  
})
