const methods = {
    // # 수정모드 > 사이즈 측정
    getTextWidth2(text, fontSize, fontWeight, lineHeight, letterSpacing, fontFamily) {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = `${fontWeight} ${fontSize} ${fontFamily}`
        context.letterSpacing = letterSpacing
        context.lineHeight = lineHeight
        return context.measureText(text).width
    },

    // # 수정모드 > 사이즈 조절
    dynamicWidth2(item) {
        const fontSize = '1.25rem'
        const fontWeight = '600'
        const lineHeight = '2rem'
        // const letterSpacing = '0.009375em'
        const letterSpacing = '0.0125em'
        // const letterSpacing = '0'
        const fontFamily = '"Noto Sans KR", sans-serif'
        return this.$getTextWidth2(this.$pretty_operator2(item.edit_operator), fontSize, fontWeight, lineHeight, letterSpacing, fontFamily) + 'px'
    },

    // # 수정모드 시작
    edit_start2(list, item){
        // 다른 수정들 모두 해제
        list.forEach(e => e.edit = false)
        list.forEach(e => e.edit2 = false)

        // 수정모드로 상태값 변경
        item.edit2 = true

        // 수정모드로 VUEX 업데이트
        this.$store.commit('update_edit_mode2', true)
        this.$store.commit('update_edit_mode', false)

        // 수정값에 대입
        item.edit_operator = this.$pretty_operator(item.operator)

    },

    // # 수정모드 종료
    edit_end2(list){
        console.log("edit_end2")
        // 수정중인 아이템이 없는 경우 바로 종료
        const isEditing = list.some(item => item.edit2)
        if (!isEditing) {
            // 수정모드로 VUEX 업데이트
            this.$store.commit('update_edit_mode2', false)
            return
        }
        

        // 비활성 키 체크
        this.$checkDisableKey(list)

        // # 수정값 세팅
        let editIndex = list.findIndex(item => item.edit2)
        let editItem = list[editIndex]
        let isLastItem = editIndex === list.length - 1

        if(!editItem.edit_operator){
            if(!editItem.number){
                this.list.splice(editIndex, 1)

                // 수정모드로 VUEX 업데이트
                this.$store.commit('update_edit_mode2', false)
            }
            else{
                console.log("HI22")
                // editItem.edit_operator = "＋"
                return
            }
        }else{
            editItem.operator = this.$unpretty_operator(editItem.edit_operator)
            editItem.edit_operator = null
            editItem.edit2 = false

            // 수정모드로 VUEX 업데이트
            this.$store.commit('update_edit_mode2', false)
        }
    },
}

export default methods