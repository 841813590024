import caculator from '@/methods/caculator.js'
import commas from '@/methods/commas.js'
import edit_mode from '@/methods/edit_mode.js'
import edit_mode2 from '@/methods/edit_mode2.js'
import formula from '@/methods/formula.js'
import keys from '@/methods/keys.js'
import results from '@/methods/results.js'

export default {
    install (Vue) {
        // # 계산 로직
        Vue.prototype.$removeTrailingZeros = caculator.removeTrailingZeros
        Vue.prototype.$removeInfiniteDecimal = caculator.removeInfiniteDecimal
        Vue.prototype.$convertFormulaListToString = caculator.convertFormulaListToString
        Vue.prototype.$addMissingParentheses = caculator.addMissingParentheses
        Vue.prototype.$isParenthesesOpen = caculator.isParenthesesOpen
        Vue.prototype.$pretty_operator = caculator.pretty_operator
        Vue.prototype.$pretty_operator2 = caculator.pretty_operator2
        Vue.prototype.$unpretty_operator = caculator.unpretty_operator
        Vue.prototype.$caculateNumberToMOJ = caculator.caculateNumberToMOJ
        Vue.prototype.$caculateNumberToMOJ2 = caculator.caculateNumberToMOJ2
        Vue.prototype.$caculateNumberToKMB = caculator.caculateNumberToKMB
        Vue.prototype.$caculateNumberToKMB2 = caculator.caculateNumberToKMB2
        Vue.prototype.$caculateMOJtoKMB = caculator.caculateMOJtoKMB
        Vue.prototype.$caculateKMBtoMOJ = caculator.caculateKMBtoMOJ
        Vue.prototype.$caculateTo000 = caculator.caculateTo000

        // # 콤마
        Vue.prototype.$inputToComma = commas.inputToComma
        Vue.prototype.$toComma = commas.toComma
        Vue.prototype.$toUnComma = commas.toUnComma

        // # 수정모드
        Vue.prototype.$getTextWidth = edit_mode.getTextWidth
        Vue.prototype.$dynamicWidth = edit_mode.dynamicWidth
        Vue.prototype.$edit_start = edit_mode.edit_start
        Vue.prototype.$edit_end = edit_mode.edit_end

        // # 수정모드2
        Vue.prototype.$getTextWidth2 = edit_mode2.getTextWidth2
        Vue.prototype.$dynamicWidth2 = edit_mode2.dynamicWidth2
        Vue.prototype.$edit_start2 = edit_mode2.edit_start2
        Vue.prototype.$edit_end2 = edit_mode2.edit_end2

        // # 계산식
        Vue.prototype.$formula_main = formula.formula_main
        Vue.prototype.$formula_sub = formula.formula_sub

        // # 결과값
        Vue.prototype.$result_main = results.result_main
        Vue.prototype.$result_sub = results.result_sub
        Vue.prototype.$result_currency_main = results.result_currency_main
        Vue.prototype.$result_currency_second_main = results.result_currency_second_main
        Vue.prototype.$result_currency_sub = results.result_currency_sub
        Vue.prototype.$result_currency_second_sub = results.result_currency_second_sub

        // # 키
        Vue.prototype.$isLastKeyNumberKey = keys.isLastKeyNumberKey
        Vue.prototype.$lastNumberKeySet = keys.lastNumberKeySet
        Vue.prototype.$lastNumberKeySet2 = keys.lastNumberKeySet2
        Vue.prototype.$keyList_Handler = keys.keyList_Handler
        Vue.prototype.$checkDisableKey = keys.checkDisableKey
        Vue.prototype.$keyType = keys.keyType
    }
}